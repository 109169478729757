.teacher-wrapper-container {
    display: flex;
    background-color: map-get($theme-colors, base-background-grey);

    .menu-button {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        border-radius: 0;
        height: 50px;
        position: absolute;
    }

    .details {
        .mt-10 {
            margin-top: 10px;
        }
    }
}

.drawer-header {
    width: 240px;
    display: flex;
    padding: 1em 1.5em;
    align-items: center;
    min-height: 40px;
    box-shadow: $header-shadow;
    white-space: break-spaces;

    .profile-logo {
        width: 2em;
        margin-right: 0.75em;
    }

    h1 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.sidebar-container-desktop > .makeStyles-drawerPaper-6 {
    width: 280px;
    border: none;
    box-shadow: $header-shadow;

    // @include md {
    //   width: 230px;
    // }
}

.MuiAutocomplete-groupLabel {
    color: map-get($theme-colors, base-blue-black) !important;
}

.sidebar-options-list-container {
    .sidebar-options-list {
        padding: 32px 16px 32px 24px;

        .quick_actions {
            margin-top: 24px;
        }

        .sidebar-option {
            padding: 1em 0;
        }

        .heading-one {
            font-size: 1em;
            padding-bottom: 0.5em;
        }

        .badge-container {
            display: flex;
            flex: 1;
        }

        a {
            color: map-get($theme-colors, base-black);
            text-decoration: none;
            .MuiListItem-root {
                padding-left: 0;
            }
        }

        .class-programme-icon {
            width: 24px;
            height: 24px;
            background: map-get($theme-colors, principal-maths-light);
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            img {
                width: 20px;
                height: 23px;
                object-fit: contain;
            }
        }
        .class-reading-icon {
            width: 24px;
            height: 24px;
            background: map-get($theme-colors, principal-reading-light);
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            img {
                width: 26px;
                height: 14px;
                object-fit: contain;
            }
        }
        .common_nav_list {
            .on_nonlink_list {
                padding: 4px 20px 4px 0px;
            }
        }
    }

    .MuiListItemIcon-root {
        min-width: 45px;
    }

    .class_and_groups {
        padding: 0 16px 0 24px;
    }
}

.teacher-wrapper-contant {
    padding: 1.5em;
    flex-grow: 1;
    margin-top: 6em;

    @include sm {
        padding: 1em;
        margin-top: 3em;
    }
    @include md {
        padding: 1em;
        margin-top: 3em;
    }
}

.show-mobile {
    display: none;
    @include sm {
        display: flex;
    }
}

.hide-mobile {
    display: flex;
    @include sm {
        display: none;
    }
}

.sidebar-container-desktop > .MuiDrawer-paper {
    width: 280px;

    // @include md {
    //   width: 230px;
    // }
}
.sidebar-container-mobile > .MuiDrawer-paper {
    width: 280px;

    // @include md {
    //   width: 230px;
    // }
}

.button-icons {
    height: 25px;
    width: 25px;
}

.active-tab {
    path {
        fill: map-get($theme-colors, principal-main-base);
    }
}

.custom-btn-class {
    width: 100%;

    .MuiButton-label {
        font-weight: normal;
        padding-left: 0;
        justify-content: flex-start;
    }
}

.class_and_groups_mini {
    position: relative;
    display: none;
    .mini_sidebar_container {
        padding: 16.5px 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .active::before {
            content: '';
            position: absolute;
            height: 34px;
            width: 4px;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);

            border-right: 1px solid #f28d00;
            background-color: #f28d00;
        }

        .mini_sidebar_gol {
            background-color: #d6d1cb;
            padding: 7px;
            border-radius: 50%;
            font-weight: 700;
        }

        .active {
            background-color: #f28d00;
        }
    }
}
