.faq-container {
    .main-heading {
        margin: 2rem 0 4rem 0;

        @include sm {
            display: none;
        }
    }
}

.faqs_container {
    margin: 0px auto;
    margin-top: 160px;
    flex-direction: column;

    @include sm {
        margin-top: 100px;
    }

    .heading {
        margin-bottom: 40px;
        font-size: 32px;
        text-align: center;
        line-height: 45px;

        @include sm {
            margin-bottom: 24px;
        }
    }
}
