.accountless-option-container {
    .accountless-options-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        .lwp-social-button-wrapper {
            min-width: 315px;
            box-shadow: 0px 4px 8px rgba(214, 209, 203, 0.5);
            .MuiPaper-root {
                box-shadow: none;
            }
        }
    }
    @include sm {
        .accountless-options-button-container {
            width: 100%;
            .lwp-social-button-wrapper {
                min-width: 100%;
            }
        }
    }
}
