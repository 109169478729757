.pupils_not_assigned {
    .acton_icon {
        margin-left: -18px;
    }
    .MuiDataGrid-columnSeparator {
        display: none;
    }
    .MuiDataGrid-columnHeaderTitle {
        font-weight: bold;
    }
    .MuiCheckbox-root {
        color: black;
    }
    .MuiDataGrid-footerContainer {
        display: none;
    }
    .pupils_delete {
        color: #fff;
    }
    .snackBar_close {
        color: #fff;
    }
}
