.delete_confirmation_modal_container {
    .dialog-title {
        padding-bottom: 0;
        margin-bottom: 16px;
    }

    .MuiDialogContent-root {
        padding-top: 0;
    }

    .mb-25 {
        margin-bottom: 25px;
    }
}
