.cannot-link-account-container {
    .common_status_container {
        .common_status_img{
            margin-top: 100px;
        }
        h1{
            margin-top: 25px;
        }
        p {
            width: 500px;
        }

        .cannot-link-account-button-container {
            display: flex;
            width: 400px;
            justify-content: space-evenly;
        }

        @include sm{
            .common_status_img{
                margin-top: 68px;
            }
            h1{
                margin-top: 10px;
            }
            p {
                width: 100% ;
            }
            .cannot-link-account-button-container {
                width: 100%;
                flex-direction: column;
                .lwp-button{
                    margin-top: 30px;
                }
            }
        }
    }
}