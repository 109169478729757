.text-wrap-forground {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        position: absolute;
    }
}

.sassoon_font_big_heading {
    font-size: 48px;
    font-family: SassoonInfantRg-Regular;
    line-height: 67.2px;
}

// .list_styling {
//     ul,
//     ol {
//         margin-top: 0;
//         padding-left: 20px;
//     }
// }

.guess_the_animal {
    .btn_group {
        a {
            color: map-get($theme-colors, base-black);
            text-decoration: none;
        }
    }
}

.guess_the_animal_container_modal {
    .mein_heading {
        line-height: 35px;
    }
}

.guess_the_animal_container {
    padding: 80px 160px;

    @include sm {
        padding: 24px;
    }

    .react_material_body {
        .typography-text::first-letter {
            text-transform: lowercase;
        }
    }

    .die-text {
        text-align: center;
        white-space: pre;

        @include sm {
            white-space: normal;
        }
    }

    .mein_heading {
        line-height: 35px;
    }

    .italic {
        font-style: italic;
    }

    ul,
    ol {
        line-height: 22.5px;
    }

    .mid_divider {
        margin: 40px 0;
    }

    .btn_container,
    .btn_group {
        text-align: center;
        margin: 40px 0;

        button {
            margin-right: 10px;
            margin-left: 10px;
            // height: 36px;
        }
    }

    .guessTheAnimalBtnGroup {
        display: block;
    }

    .btn_group {
        button {
            @include sm {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }

    .img_container {
        display: flex;
        justify-content: center;
        margin: 40px auto 0 auto;
        max-width: 500px;

        img {
            width: 100%;
        }
    }
    // .sounds_scavenger_img {
    //     margin-bottom: 40px;
    // }

    ul {
        margin: 16px 0;
        padding-left: 40px;

        @include sm {
            padding-left: 10px;
        }
    }

    .dialog-content-section {
        max-width: 1030px !important;
    }

    .list_container {
        margin-left: 16px;
    }
}

.pop_over {
    display: inline-block;

    .textClassName {
        text-decoration: underline;
    }

    .typography-text::first-letter {
        text-transform: lowercase;
    }
}

.typography {
    max-width: 245px;
}

._span {
    color: map-get($theme-colors, principal-main-base);
}
._span_red {
    color: map-get($theme-colors, auxiliary-red-base);
}
._span_green {
    color: map-get($theme-colors, principal-maths-base);
}
._span_blue {
    color: map-get($theme-colors, principal-english-base);
}
._span_purple {
    color: map-get($theme-colors, principal-reading-base);
}

._span_custom {
    .textClassName {
        @extend ._span;
    }
}

._span_custom_green {
    .textClassName {
        @extend ._span_green;
    }
}
._span_custom_blue {
    .textClassName {
        @extend ._span_blue;
    }
}

// .clap_clap_description {
//     .btn_container,
//     button {
//         // height: 36px;
//     }
// }

.clap_clap_modal {
    .img_container {
        display: flex;
        justify-content: center;
        margin: 40px auto 0 auto;
        max-width: 328px;

        img {
            width: 100%;
        }
    }
}

.guess_the_animal_container_modal {
    line-height: 22.5px;

    .top_divider {
        margin: 8px 0 16px 0;
    }

    .back_btn {
        width: 151px;
        height: 52px;

        @include sm {
            width: 100%;
        }
    }

    .dialog_content {
        border-radius: 4px;
    }

    .dialog-title {
        padding: 16px 0;
    }

    .guess_the_animal_container_nested {
        padding: 64px 127px;

        @include sm {
            padding: 24px 0;
        }
        .heading-one {
            margin-bottom: 32px;
        }
    }
}

.check_box_card {
    width: 200px;
    height: 200px;
    box-shadow: 0px 4px 8px rgba(214, 209, 203, 0.5) !important;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 24px;

    .digital-checklist-image {
        min-height: 0;
        max-height: 100%;
        max-width: 100%;
        object-fit: scale-down;
        padding-top: 1em;
        padding-bottom: 1em;
        flex-grow: 1;
    }

    .check_box_container {
        flex: 0;

        .MuiFormControlLabel-root {
            margin-right: 0;
        }
    }
}

.sub_heading {
    font-size: 24px;
}

.finger_space_lolly_stick {
    line-height: 22.5px;

    .big_title {
        font-size: 48px;
        line-height: 67px;
        font-weight: 400;
        font-family: SassoonInfantRg-Regular;
        margin: 0 auto;
    }

    .write_a_sentence {
        margin-top: 32px;
        margin-bottom: 40px;
    }

    .click_one_of_the_buttons {
        margin: 40px 0;
    }

    .finger_space_lolly_stick_img_container {
        margin: 0;
        text-align: center;
        justify-content: center;

        .grid_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .lwp-button {
        margin: 40px 0;
        width: 152px;
        // height: 36px;
    }
}

.inner_dialog_container {
    max-width: 680px;
    margin: 0 auto;

    ul {
        padding-left: 20px;
    }
}

.dialog_img {
    text-align: center;
    margin-top: 40px;
}

.happy_birthday {
    .lwp-button {
        // width: 276px;
        // height: 36px;

        @include sm {
            width: 100%;
        }
    }

    ul,
    ol {
        margin-top: 0;
        padding-left: 20px;
    }

    .sub_heading {
        font-size: 24px;
    }

    .para {
        margin: 40px 0;
    }

    .birthday_img_container {
        margin-top: 30px;
        text-align: center;
    }

    .btn_container {
        text-align: center;
    }
}

.top_five_animals {
    .lwp-button {
        max-width: 196px;

        @include sm {
            width: 100%;
        }
    }
}

.making_words,
.opposites,
.improveIt {
    button {
        margin-right: 0 !important;
        margin-left: 0 !important;

        @include sm {
            font-size: 14px;
        }
    }
}

.hidden_item {
    visibility: hidden;
    height: 0;
    line-height: 0;
}

.activity_modal {
    ul,
    ol {
        padding-left: 20px;
    }

    .italic {
        font-style: italic;
    }
}

.bg_img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}

.opposites {
    .buble_conainer {
        @extend .bg_img;
        max-width: 400px;
        height: 192px;
        line-height: 50px;

        @include customRange(0, 400) {
            line-height: 32px;
        }

        .paragraph {
            line-height: 36.5px;

            @include customRange(0, 400) {
                font-size: 12px;
                line-height: 22.5px;
            }
        }
    }
}

.beginning_because {
    .buble_conainer {
        @extend .bg_img;

        .paragraph {
            line-height: 50px;

            @include customRange(0, 600) {
                font-size: 12px;
                line-height: 22.5px;
            }
        }
    }
}

.UpTheDictionary {
    ul,
    .hide_marker {
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
    }

    .buble_conainer {
        @extend .bg_img;
        max-width: 404px;
        height: 250px;

        .sub_heading {
            line-height: 35px;
        }

        .discription {
            text-align: start;
        }

        .paragraph {
            margin: 0 5rem;
            margin-left: 0;
        }

        @include customRange(0, 600) {
            .paragraph {
                line-height: 15px;
                font-size: 12px;
                margin: 0 1rem;
            }

            .sub_heading {
                font-size: 20px;
            }
        }
    }
}

.beginning_because {
    u {
        cursor: pointer;
    }

    .buble_conainer {
        p {
            font-family: SassoonInfantRg-Regular;
            font-size: 28px;
            line-height: 39px;
        }
        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

.animal_vegetable_mineral {
    .lwp-button {
        width: 152px;

        @include sm {
            width: 100%;
        }
    }
    ._heading {
        @include sm {
            font-size: 20px;
        }
    }

    .MuiButton-containedSizeLarge {
        font-size: 0.9rem;
    }

    .animals_imgs_container {
        display: flex;
        align-items: center;
        justify-content: center;

        @include sm {
            flex-wrap: wrap;
        }

        img {
            width: 100%;
            height: 100%;
            margin: 12px;
        }
    }
}

.clap_clap {
    .btn_container {
        button {
            height: auto;
        }
    }

    ul,
    ol {
        margin-top: 0;
        padding-left: 20px;
    }
}

.spelling_out_my_name {
    .unorder_list {
        padding-left: 20px;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.making_the_alphabet {
    .alphabets_images_container {
        display: flex;
        overflow: auto;

        img {
            margin: 0 3px;
        }
    }

    .download_alphabets_PDF {
        .text_span {
            font-family: SassoonInfantTk-Regular2;
        }
    }
}

.my_room_activity_container {
    .my_room_check_box_card {
        width: 200px;
        height: 200px;
        box-shadow: 0px 4px 8px rgba(214, 209, 203, 0.5) !important;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        flex-direction: column;
    }

    ul,
    ol {
        margin-top: 0;
        padding-left: 20px;
    }
}

.speed_reading {
    .btn_container {
        .MuiButton-containedPrimary {
            @include sm {
                padding: 8px 0;
            }
        }
    }
}

.the_questions_game {
    .btn_container {
        margin: 24px 0;
    }
}

.chair_in_the_world,
.help_me_Tell_a_Story {
    .btn_container {
        margin: 24px 0;
    }

    .lwp-button {
        width: auto;
    }
}

.good_and_bad {
    u {
        cursor: pointer;
    }
    .btn_container {
        margin: 24px 0;
    }

    .lwp-button {
        width: auto;
    }
}

.shifting_sentences {
    ul {
        margin-bottom: 0;
    }
}

.spelling_posters {
    .big_heading {
        font-size: 48px;
        font-family: SassoonInfantTkB-Regular;
    }
}

.spot_the_difference,
.SaidSwap,
.WhatIf {
    .big_heading {
        @extend .sassoon_font_big_heading;
    }

    .img_container {
        @include sm {
            margin-top: 20px;
        }
    }
}

.rainbow_container {
    .child_name {
        line-height: 67.5px;
    }
}

.writing_my_full_name {
    .img_container {
        text-align: center;
    }
    img {
        width: 100%;
        max-width: 500px;
    }
}

.link {
    color: map-get($theme-colors, base-black);
}

.FindAWord {
    ul {
        margin: 0;
    }

    .btn_container {
        margin-bottom: 24px;
        margin-top: 24px;
    }
}

.find_a_word_dialog {
    .dictionaryCheckbox {
        margin: 5px 0;

        span {
            padding-left: 0;
        }
    }
}

.happy_birthday {
    .diamondBtn {
        width: auto;
    }
}

.find_a_word_dialog {
    .diamond {
        font-size: 24px;
        font-family: Lucida-Console-Regular;
        font-weight: 400;
        line-height: 31.08px;
        text-transform: lowercase;
    }

    .typography-text::first-letter {
        text-transform: lowercase;
    }
}

.find_a_word_dialog {
    .exampleGame li {
        list-style: none;
    }
}
.spelling_jars_dialog {
    .bold_fonts {
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        line-height: 44.8px;
        margin: 40px 0;
        text-transform: uppercase;
        letter-spacing: 12px;
        cursor: pointer;
        line-break: anywhere;
    }
}

.i_fee_said {
    .btn_container {
        margin: 10px 0 20px 0;

        .download_PDF_btn {
            .lwp-button {
                color: black;
                box-shadow: none !important;
                text-transform: none;
                font-weight: 700;
                min-width: 64px;
                padding: 8px 22px;
                border-radius: 4px;
                background-color: #e0e0e0;
            }

            .lwp-button:hover {
                background-color: #d5d5d5;
            }
        }
    }
}

.why,
.guess_My_Word {
    .img_container {
        margin-top: 10px;
    }
}

.diamond_words {
    .btn_container {
        margin: 24px 0;
    }
}

.what_is {
    .choose_one {
        margin-bottom: 0;
    }

    .img_container {
        margin-top: 20px;
    }
}

.LightningInTheFridge {
    .heading-one {
        line-height: 40px;
    }

    .lwp-button {
        width: auto;
    }
}

.help_me_Tell_a_Story {
    .MuiTabs-root {
        width: 100%;
        overflow: visible;
    }
    .MuiTabs-flexContainer {
        display: flex;
        justify-content: center;
    }

    .tebs {
        width: 100%;
        @include sm {
            width: 50%;
        }
    }

    .lwp-button {
        width: auto;
    }

    .die-text-container {
        width: 236px;
        height: 116px;
    }
}
.Word_Wheels,
.Word_Wheels_dialog {
    .btn_container {
        margin: 24px 0;
    }

    .lwp-button {
        width: auto;
    }

    .die-image-container {
        box-shadow: none;
    }

    .die-text {
        font-size: 50px;
    }
}

.draw_it {
    .die-image-container {
        box-shadow: none;
    }

    .die-text-container,
    .die-image {
        display: flex;
        justify-content: center;
        align-items: center;

        // img {
        //     width: 100%;
        //     max-width: 116px;
        //     max-height: 116px;
        // }
    }
}

.word_cards {
    img {
        max-width: 198px;
    }
}

.TheTravellingAlphabet {
    .img_container {
        img {
            max-width: 325px;
        }
    }
}

.help_me_Tell_a_Story {
    .typography-text::first-letter {
        text-transform: capitalize;
    }
}

.the_why_boat {
    // .unordered_list {
    //     list-style-position: inside;
    // }

    .img_container {
        margin-top: 25px;
    }

    .btn_container {
        margin-top: 25px;
        margin-bottom: 20px;
    }
}

.the_why_boat_dialog {
    .card_body {
        text-align: center;
    }
    img {
        width: 100%;
    }

    .card_img {
        max-width: 144px;
        min-height: 165px;
        max-height: 165px;
    }

    .last_card_img {
        max-width: 483px;
    }

    .title {
        min-height: 44px;
    }
}

.interviewing_my_family {
    .btn_container {
        margin: 20px 0;
    }
}

.book_review_chat {
    .bg_trnsparent:before {
        background-color: transparent;
    }

    .unordered_list {
        width: 100%;
    }

    .img_container {
        max-width: 500px;
        margin: 24px auto;

        img {
            width: 100%;
        }
    }
}

.adventure {
    .adventure_img_container {
        max-width: 498px;
        margin: 0 auto;
        img {
            width: 100%;
        }
    }

    // ol {
    //     list-style-position: inside;
    // }

    ul {
        margin-top: 0;
        padding-left: 8px;
    }
}

.whats_in_the_bag {
    .MuiTabs-flexContainer {
        align-items: center;
        justify-content: center;

        @include sm {
            align-items: unset;
            justify-content: unset;
        }
    }

    .img_container {
        margin-top: 20px;
    }

    .btn_container {
        margin: 24px 0;
    }
}

.imagine_you_are_there {
    .die-text-container {
        width: 100%;
        height: 100%;
    }

    .die-text {
        font-family: SassoonInfantRg-Regular;
        font-size: 48px !important;
        padding: 20px;
    }
}

.alphabetical_categories {
    .MuiTabs-flexContainer {
        align-items: center;
        justify-content: center;

        @include sm {
            align-items: unset;
            justify-content: unset;
        }
    }
    .die-text-container {
        width: 100%;
        min-width: 231px;
        height: 100%;
        min-height: 68px;
    }

    .die-text {
        font-family: SassoonInfantRg-Regular;
        font-size: 48px !important;
        padding: 20px;
    }
}

.alphabetical_categories_dialog {
    .big_alphabets {
        font-family: SassoonInfantRg-Regular;
        font-size: 48px !important;
        text-align: center;
        width: 100%;
        display: inline-block;
        line-height: 67px;
    }
}

.on_or_in {
    .MuiGrid-container {
        justify-content: center;
        gap: 0 27px;
    }

    .card_body {
        img {
            height: 166px;
            width: 190px;
            object-fit: contain;
        }
    }

    .MuiGrid-root {
        text-align: center;
    }

    .btn_container {
        margin: 24px 0;
    }
}
