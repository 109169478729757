.interstitials-container {
    width: calc(100% - 60px);
    min-height: calc(100% - 140px);

    position: absolute;
    top: 80px;
    left: 0;
    padding: 30px;
    display: flex;

    @include sm {
        width: calc(100% - 20px);
        min-height: calc(100% - 100px);
        padding: 10px;
    }

    .interstitial-header-container {
        h1 {
            font-size: 32px;
            margin-bottom: 0.2em;
            line-height: 50px;
        }
        .interstitial-schoolName-text {
            font-size: 20px;
            margin-bottom: 0.2em;
        }

        @include sm {
            margin-top: 10px;
        }
    }

    .image-container {
        display: flex;
        justify-content: center;
        margin-bottom: 13px;

        img {
            width: 110px;
            max-height: 110px;
            object-fit: contain;
        }
    }

    .interstitials_Card_wrapper {
        background-color: #fff;
        width: 100%;
        margin: 0 auto;

        .interstitials_Card {
            height: calc(100% - 10px);
            max-width: 1032px;
            margin: auto;
            padding: 20px;

            display: flex;
            flex-direction: column;
            align-items: center;

            @include sm {
                padding: 10px 15px;
            }

            .interstitials-stepper {
                background: transparent;
                margin-bottom: 8px;

                .MuiMobileStepper-dotActive {
                    background-color: map-get($theme-colors, principal-light);
                }
            }

            .interstitial-confirm-invitation {
                display: flex;
                flex-direction: column;
                align-items: center;
                h1 {
                    font-size: 20px;
                }
                .interstitials_notCorrect {
                    cursor: pointer;
                }
            }

            .welcome_info_second_content {
                .second_content {
                    margin-bottom: 24px;
                    display: flex;
                    gap: 16px;
                    img {
                        width: 68px;
                    }
                    h3 {
                        margin-bottom: 8px;
                    }
                    .content {
                        width: 100%;
                    }
                }
            }

            .welcome_info {
                margin-top: 40px;
                margin-bottom: 24px;

                h1 {
                    font-size: 24px;
                    margin-bottom: 24px;
                }

                img {
                    width: 98%;
                    display: flex;
                    margin: auto;
                }

                .welcome_info_content {
                    display: flex;
                    margin-top: 40px;
                    gap: 80px;
                    text-align: center;

                    .content {
                        width: 100%;
                    }
                }
            }

            .interstitials_btn {
                display: flex;
                gap: 16px;

                @include sm {
                    width: 100%;
                    justify-content: center;
                    flex-direction: column-reverse;
                    align-items: center;
                    margin-bottom: 10px;

                    span {
                        width: 100%;
                        .lwp-button {
                            width: 100%;
                        }
                    }
                }
            }

            .marleft {
                margin-right: 140px;
                @include sm {
                    margin: 0;
                }
            }

            .interstitial-new-mathscot {
                margin-top: 40px;
                h1 {
                    font-size: 24px;
                    margin-bottom: 24px;
                }
                p {
                    max-width: 650px;
                    margin-top: 24px;
                }
                .common_card {
                    width: max-content;
                    margin: auto;
                    padding: 15px;
                }
            }

            .interstitials-policy-container {
                margin-top: 40px;
                margin-bottom: 24px;

                img {
                    display: flex;
                    margin: auto;
                }
                .interstitials-policy {
                    margin-top: 48px;
                    max-width: 520px;
                    padding: 24px 16px 24px 32px;

                    border: 4px solid;
                    border-image: linear-gradient(270deg, #ffd7a3 0.01%, #fff6d9 40.62%, #ffe0b3 77.58%, #d0d8ff 99.97%);
                    border-image-slice: 1;

                    @include sm {
                        padding: 16px;
                    }

                    .interstitials-policy-btn {
                        margin-top: 24px;
                        display: flex;
                        justify-content: center;
                        button {
                            padding: 16px 40px;
                        }

                        @include sm {
                            .lwp-button-wrapper {
                                width: 100%;
                            }
                            button {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mask-default {
    background-image: url('../../../../public/assets/images/Mask-group-orange.png');
}
.mask-un-default {
    background-color: #fbdbb5;
}
.mask-reading {
    background-image: url('../../../../public/assets/images/Mask-group-reading.png');
}
.mask-un-reading {
    background-color: map-get($theme-colors, principal-reading-light);
}
.mask-fullProgram {
    background-image: url('../../../../public/assets/images/Mask-group-fullProgram1.png');
}
.mask-un-fullProgram {
    background-color: #d4e5ab;
}
