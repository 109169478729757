.mathscot_container {
    .grid_img {
        display: flex;
        height: 75px;

        img {
            height: 75px;
            object-fit: contain;
        }

        .mathscot_img {
            margin: 0 auto;
            height: 85px;

            img {
                height: 85px;
            }
        }

        box-shadow: 0px 4px 8px 0px rgba(214, 209, 203, 0.5);

        &:hover {
            box-shadow: 0px 2px 12px rgba(242, 141, 0, 0.5);
        }

        @include sm {
            padding: 5px;
        }
    }

    .active {
        box-shadow: 0px 2px 12px rgba(242, 141, 0, 0.5);
    }

    .mathscot_2 {
        .grid_rev {
            flex-direction: column;
        }
        @include sm {
            .grid_img {
                padding: 10px;
            }
        }
    }

    .mathscote_btn {
        margin-top: 35px;
        display: flex;
        justify-content: center;
    }

    .img_custum_1 {
        margin: auto;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        max-width: 376px;
        max-height: 500px;
        // width: 100%;
        height: 100%;
        @include sm {
            width: 100% !important;
        }
    }
    .img_custum_2 {
        position: absolute;
        height: 135px;
        top: 42%;
        left: 31%;
    }

    .paragraph_text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        @include sm {
            font-size: small;
        }
    }
}
