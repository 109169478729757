.survey-modal {
    .survey-modal-content-container {
        width: 650px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
            height: 194px;
            object-fit: contain;
            margin-top: 49px;
        }
        h1 {
            margin: 27px 0;
        }
        .survey-modal-text {
            text-align: center;
            margin-bottom: 24px;
        }
        .survey-modal-text-list {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 8px 0;
            h3 {
                color: map-get($theme-colors, principal-main-dark);
                margin-right: 10px;
                text-align: center;
            }
            h3::before {
                margin: 0 10px 0 5px;
                content: '\2022';
            }
        }
        .survey-modal-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 72px;
            margin-top: 33px;
            .survey-modal-button-container {
                display: flex;
                align-items: center;
                gap: 25px;
            }
        }
    }
    @include sm {
        .survey-modal-content-container {
            width: 100%;
            img {
                margin-top: 39px;
            }
            h1 {
                margin: 20px 0;
            }
            .survey-modal-text-list {
                gap: 8px;
            }
            .survey-modal-bottom {
                display: flex;
                flex-direction: column;
                margin-top: 48px;
                margin-bottom: 0;
                gap: 18px;
                .survey-modal-button-container {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
        }
    }
}
