.book_history_container {
    position: relative;
    .MuiCardContent-root {
        padding: 25px;
    }

    .MuiCardContent-root:last-child {
        padding-bottom: 25px !important;
    }

    .bookname {
        text-align: center;
        margin-bottom: 16px;
        min-height: 38px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .book_history_card {
        .book_history_card_container {
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                left: 0px;
                top: 0;
                border-top: 4px solid map-get($theme-colors, principal-reading-base);
                background-color: map-get($theme-colors, principal-reading-base);
            }

            .MuiChip-label {
                padding: 0 8px;
            }
        }
    }
}

.book_history_more {
    color: map-get($theme-colors, dark-warm-grey);
}
