.impact-report {
    .impact-report-container {
        height: 140px;

        @include maw(1439) {
            height: auto;
        }

        .impact-report-background {
            width: -webkit-fill-available;
            width: -moz-available;
            background-color: #fef3e2;
            box-shadow: 0 0 0 100vmax #fef3e2;
            clip-path: inset(0 -100vmax);
            position: absolute;
            top: 0;

            @include maw(1439) {
                position: relative;
            }

            @include sm {
                .impact_report_logo {
                    display: none;
                }
            }

            .impact-report-content {
                max-width: 1080px;
                padding: 0 16px;
            }
        }
    }
    .share-print-btn {
        display: flex;
        gap: 20px;

        @include maw(450) {
            flex-direction: column;
            width: 100%;
        }
    }
}

.report-sections-container {
    border-radius: 6px;

    > .common_card {
        border-top: 6px solid map-get($theme-colors, principal-reading-base);
        > .MuiCardContent-root {
            display: flex;
            flex-direction: column;
            gap: 64px;

            .graph-container {
                width: 100%;

                .common_card {
                    .MuiCardContent-root {
                        padding: 24px !important;
                        .heading-three {
                            font-size: 1rem;
                            font-weight: bold;
                        }
                    }
                    .active-families-content {
                        h3 {
                            display: inline;
                        }
                    }
                }
            }
        }
    }
}

.highcharts-exporting-group {
    display: none;
}

// For the reading report, the border-top color is principal-reading-base
.reading-impact-report {
    > .common_card {
        border-top: 6px solid map-get($theme-colors, principal-reading-base);
    }
}

// For the topic report, the border-top color is principal-main-base
.topic-and-activity-impact-report {
    > .common_card {
        border-top: 6px solid map-get($theme-colors, principal-maths-base);
    }
}

.program-overview {
    .common_card {
        .MuiCardContent-root {
            padding: 24px;
            .heading-three {
                font-size: 1rem;
                font-weight: bold;
            }
        }
        .active-families-content {
            h3 {
                display: inline;
            }
        }
    }
}
