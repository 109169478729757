.add_holiday_modal {
    .holiday-date-container {
        display: flex;
        justify-content: space-between;
        gap: 30px;
    }
    .holiday-date-error {
        margin-top: 8px;
        p {
            font-size: 12px;
            color: map-get($theme-colors, auxiliary-red-base);
        }
    }
    .btn_group {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
}

.school-details {
    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        margin-top: 44px;
        .MuiListItemIcon-root {
            min-width: 34px;
        }
    }
    .holiday-alert {
        margin-bottom: 24px;
        background-color: map-get($theme-colors, auxiliary-blue-light);
        .MuiAlert-icon {
            color: map-get($theme-colors, auxiliary-blue-base);
            font-size: 26.67px;
        }
    }
    .common_table {
        .MuiPaper-root {
            box-shadow: 0px 4px 8px rgba(214, 209, 203, 0.5);
            .MuiTableCell-root {
                padding: 16px 32px;
                .MuiButtonBase-root {
                    .material-icons {
                        color: map-get($theme-colors, base-blue-black);
                    }
                }
            }
        }
    }
    .sticker-section {
        .sticker-section-header {
            margin-bottom: 24px;
            h2 {
                font-family: Chaloops-Medium;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
            }
            a {
                text-decoration: none;
            }
        }
    }
}
