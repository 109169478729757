.school_overview {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
            font-family: Chaloops-Medium;
            font-weight: unset;
            margin-bottom: 30px;
        }
        .header-view-button {
            display: flex;
            gap: 24px;
            .MuiButtonBase-root {
                background: map-get($theme-colors, base-warm-grey);
                .material-icons {
                    color: map-get($theme-colors, base-blue-black);
                }
            }
        }
    }

    h3 {
        font-weight: unset;
        font-size: 20px;
        margin: 15px 0;
    }

    .MuiTabs-root {
        margin-bottom: 0;
    }

    .classes_overview {
        .header {
            display: flex;
            align-items: center;

            span {
                flex: 1;
            }
        }

        .arrow_cursor {
            cursor: pointer;
        }

        .school-class-filter-button,
        .user-filter-button {
            display: flex;
            align-items: baseline;
            justify-content: flex-end;
            margin-bottom: 20px;

            .filter-button {
                color: black;
                text-transform: initial;
                font-weight: bold;
            }
            .filter-icon {
                margin-top: 5px;
                margin-left: 5px;
            }
        }
        .staff-overview-header {
            align-items: center;
            .user-filter-button {
                margin-bottom: 0px;
            }
            margin-bottom: 25px;
        }

        .no_margin {
            margin: 0;
        }

        .school-class-collapse,
        .user-collapse {
            max-width: 750px;
            margin: auto;
        }

        .feeds-filter-container {
            margin-bottom: 20px;

            .MuiCardActions-root {
                justify-content: flex-end;
            }
            .MuiCardActions-spacing > :not(:first-child) {
                margin-left: 30px;
            }
        }

        .info_snackbar {
            background-color: map-get($theme-colors, principal-english-light) !important;
        }
        .info_snackbar_icon {
            color: map-get($theme-colors, principal-english-dark) !important;
            font-size: 33px;
            height: 35px;
        }
        .premium_link {
            text-decoration: underline;
            margin-left: 5px;
            cursor: pointer;
        }

        .MuiList-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        .MuiListItemIcon-root {
            min-width: 35px;
        }
        .table_main {
            .tr {
                padding: 4px 32px;
                background-color: #fcfcfd;
            }
            .tbody {
                .tr_row {
                    padding: 10px 32px;
                    background-color: white;

                    .td {
                        p {
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
        .programme-info-alert-container {
            display: flex;
            align-items: center;
            gap: 10px;
            .programme-info-image-reading {
                width: 34px;
                height: 34px;
                background-color: map-get($theme-colors, principal-reading-light);
                border-radius: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 34px;
                    height: 18px;
                    margin-left: 3px;
                    object-fit: none;
                }
            }
            .programme-info-image-fullProgramme {
                width: 34px;
                height: 34px;
                background-color: map-get($theme-colors, principal-maths-light);
                border-radius: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 28px;
                    height: 31px;
                    object-fit: none;
                }
            }
            p {
                margin-bottom: 0;
            }
        }

        .classes_overview_heading {
            font-family: Chaloops-Medium;
            display: flex;
            align-items: center;
            gap: 14px;

            .Chip_Chip {
                background: map-get($theme-colors, auxiliary-yellow-base);
            }

            h3 {
                font-size: 20px;
            }
        }
    }
    .link_classes_to_sims {
        .btn_group {
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;

            .cancel_btn {
                margin-right: 10px;
                padding: 8px 22px;
            }
        }
    }

    .details {
        h2 {
            margin-top: 10px;
        }

        .sync_wonde_classes {
            display: flex;
            flex-direction: column;
        }

        .class-licence-section {
            .heading-two {
                margin-bottom: 15px;
            }

            .MuiButtonBase-root {
                margin-bottom: 15px;
            }
        }

        .remove_imported_data {
            margin-top: 5px;
            .lwp-button {
                background-color: map-get($theme-colors, aa-red);

                &:hover {
                    background-color: map-get($theme-colors, aa-red-hover);
                }
            }
        }

        .mb-10 {
            margin-bottom: 10px;
        }

        .mt-10 {
            margin-top: 10px;
        }

        .mb-0 {
            margin-bottom: 0;
        }
        // }
        .social_login {
            form {
                max-width: 100%;
            }
        }
    }
    .generate-impact-report-container {
        .report-type {
            flex-direction: column;

            > span {
                color: #00303c;
                font-size: 1rem;
                font-weight: normal;
                margin-bottom: 10px;
            }

            .common_radio_container {
                max-width: 460px;
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 24px;
            }

            .common_radio_button .common_radio_border {
                flex: 1;
                label {
                    display: flex;
                    align-items: center;
                    margin: 0px;
                }
            }

            .form-radio-check {
                .radio-check {
                    padding-bottom: 0;
                }
            }

            .common_radio_button .common_radio_border p {
                margin: 0;
            }
        }

        .delete-impact-report-button {
            background-color: map-get($theme-colors, aa-red);

            &:hover {
                background-color: map-get($theme-colors, aa-red-hover);
            }
        }
    }
}

.classes_overview {
    .class-filter-button {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1em;
        .lwp-button {
            background-color: transparent;
        }
    }

    .class-filter-Collapse {
        max-width: 750px;
        margin: auto;
        margin-bottom: 1em;
    }

    .MuiCardActions-root {
        justify-content: flex-end;
    }
}

.social_login_info {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    p {
        margin-left: 14px;
        font-size: 14px;
        text-align: left;
    }
}

.snackbar {
    .info_snackbar {
        background-color: map-get($theme-colors, auxiliary-blue-light) !important;
    }
    .snackbar_icon {
        font-size: 33px;
        height: 35px;
    }
    .premium_link {
        text-decoration: underline;
        margin-left: 5px;
        cursor: pointer;
    }

    .MuiAlert-standardSuccess .MuiAlert-icon {
        color: map-get($theme-colors, principal-english-dark) !important;
    }
}
