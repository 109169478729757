.school_over_data {
    margin-top: 20px;
    .school_data_container {
        display: flex;
        margin-bottom: 16px;
        border-radius: 4px;
        align-items: center;
        padding: 20px;
        background-color: #ffffff;

        .school_data_discription {
            width: 780px;
            padding-right: 10px;
            .school_data_heading {
                font-size: 20px;
                margin-bottom: 12px;
            }
        }
    }

    .READING {
        border-left: 7px solid map-get($theme-colors, principal-reading-base);
    }
    .BOTH {
        border-left: 7px solid map-get($theme-colors, principal-main-base);
    }
    .TOPICS {
        border-left: 7px solid map-get($theme-colors, principal-maths-base);
    }
}
