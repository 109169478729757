.last-login-container {
    width: 500px;
    margin: 24px 0;
    .MuiPaper-root {
        padding: 40px 40px 16px 40px;
        box-shadow: 0px 4px 8px rgba(214, 209, 203, 0.5);
        .login-info-detail {
            display: flex;
            align-items: center;
            gap: 32px;
            margin-bottom: 24px;
        }
    }
}
