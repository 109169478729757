.purchase-additional-class-modal-container {
    .MuiDialogContent-root {
        padding-top: 0;
        padding-bottom: 0;
    }

    .purchase-additional-class-modal {
        .purchase-additional-class-price-field-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin: 16px 0;
            .purchase-additional-class-price-field {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                .purchase-additional-class-price-field-error {
                    .purchase-additional-class-price-field-error-text {
                        margin-top: 8px;
                        display: flex;
                        align-items: center;
                        span {
                            width: 1rem;
                            height: 1rem;
                            margin: 0 0.25em;
                            font-size: 15px;
                        }
                        p {
                            color: map-get($theme-colors, auxiliary-red-base);
                            font-size: 12px;
                        }
                    }
                }
                .MuiGrid-root {
                    margin-bottom: 0 !important;
                    min-width: 85px;
                    max-width: 85px;
                }
            }
        }
        .purchase-additional-class-checkbox {
            margin: 24px 0;
        }
    }
}
