.mobile-stepper-container {
  max-width: 400px;
  flex-grow: 1;

  .MuiMobileStepper-root {
    justify-content: center;
    background: transparent;
    margin-top: 1em;

    .MuiMobileStepper-dots > .MuiMobileStepper-dot {
      width: 1rem;
      height: 1rem;
      margin: 0 0.25em;
    }
  }

  .stepper-img {
    height: 300px;
    display: block;
    max-width: 400px;
    overflow: hidden;
    width: 100%;
    object-fit: contain;
  }
}
