.child-card-footer-contanier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  width: 100%;
  padding: 0 1em;

  .active-left-border {
    position: absolute;
    left: 0;
    height: 100%;
    border-left: 5px solid map-get($theme-colors, principal-english-base);
  }
  .active-top-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 5px solid map-get($theme-colors, principal-main-base);
  }

  .footer-contant {
    padding: 1em 0;
    width: 100%;
    .primary-text {
      font-weight: bold;
      font-size: 1em;
      width: 80%;
      color: map-get($theme-colors, base-black);
    }
    .task-text {
      font-weight: bold;
      font-size: 1em;
      width: 80%;
      color: map-get($theme-colors, principal-main-base);
      padding-top: 0.25em;
    }
    .date-text {
      font-weight: normal;
      font-size: 1em;
      width: 85%;
      color: map-get($theme-colors, base-black);
      padding-top: 0.25em;
    }
  }
  .right-icon {
    width: 2em;
    height: 2em;
    cursor: pointer;
  }

  // .truncate_local {
  //   max-width: 250px;
  //   display: -webkit-box;
  //   -webkit-box-orient: vertical;
  //   -webkit-line-clamp: 2;
  //   overflow: hidden;
  // }
}
