.mobile-bottom-nav-container {
    width: 100%;
    box-shadow: 0px -2px 8px rgba(214, 209, 203, 0.25);
    min-height: 80px;

    .nav-action-btn {
        color: map-get($theme-colors, base-warm-grey);

        .MuiBottomNavigationAction-wrapper {
            .MuiBottomNavigationAction-label {
                color: map-get($theme-colors, base-black);
            }
        }
        .MuiBottomNavigationAction-label {
            color: map-get($theme-colors, base-black);
            font-size: 12px;
            margin-top: 5.6px;
        }
    }
    .Mui-selected {
        .active-icon {
            filter: invert(55%) sepia(22%) saturate(6560%) hue-rotate(12deg) brightness(106%) contrast(103%);
        }
        .MuiTouchRipple-root {
            height: 1px;
            width: 62px;
            margin: 0 auto;
            border: 5px solid #f28d00;
            border-top: 0px;
            border-radius: 0px 0px 4px 4px;
            background: #f28d00;
        }
    }
}

.MuiBottomNavigation-root {
    display: flex;
    justify-content: space-evenly;
}
