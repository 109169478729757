.colors {
    color: map-get($theme-colors, base-black) !important;
    background-color: map-get($theme-colors, base-warm-grey) !important;
}

.react_material_container {
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiButtonBase-root {
        top: calc(50% - 30px) !important;
        // margin: 0;
    }
}

.Carousel-active-19 {
    .MuiButtonBase-root {
        color: map-get($theme-colors, principal-main-base);
    }
}

.Carousel-indicator-103 {
    .MuiButtonBase-root {
        color: map-get($theme-colors, base-warm-grey);
    }
}

.Carousel-indicatorIcon-18 {
    font-size: 24px !important;
}

.Carousel-buttonWrapper-20 {
    height: 85% !important;

    .MuiButtonBase-root {
        @extend .colors;
    }
}

.Carousel-buttonWrapper-100 {
    height: 85% !important;
    border: 1px solid;
}

.Carousel-button-104 {
    @extend .colors;
}

.Carousel-fullHeightHoverWrapper-21:hover {
    .Carousel-button-24 {
        @extend .colors;
        filter: brightness(100%) !important;
        opacity: 1 !important;
    }
}

.react_material {
    max-width: 460px;
    margin: 0 auto;
    padding: 15px;
}

.react_material_body {
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
    background-color: map-get($theme-colors, principal-light);
    padding: 16px 0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 122px;

    @include sm {
        width: 65%;
    }

    .paragraph {
        white-space: pre-line;
        font-family: SassoonInfantRg-Regular;
        font-size: 32px;
        line-height: 44.8px;
        max-width: 250px;
        // text-transform: lowercase;
    }
    .typography-text::first-letter {
        text-transform: none !important;
    }

    .Carousel-indicators-80 {
        height: 0;
    }

    .Carousel-fullHeightHoverWrapper-21 {
        height: 83%;
    }
}

.custom_button {
    @extend .colors;
    margin: 0 !important;

    @include customRange(0, 400) {
        width: 40px;
        height: 40px;
    }

    &:hover {
        opacity: 1 !important;
    }
}

.custom_wrapper_buttons {
    top: -15px;
}

.render_label {
    width: 85%;
}

.render_text {
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.render_text_scrollingImage {
    width: 340px !important;
}

.carousel-container {
    flex-direction: column;
    gap: 8px;
}

.activity_carousel_container {
    display: flex;
    align-items: center;
    overflow-y: auto;
    gap: 8px;
}

.image-fit-contain {
    object-fit: contain;
}

.hidden-carousel-container {
    position: absolute;
    visibility: hidden;
    overflow: hidden;
    height: 0;
}
