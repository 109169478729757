.forgot-password-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .common_status_container {
    max-width: 327px;

    .common_status_container_image {
      max-width: 200px;
    }
  }

  .text-fields {
    width: 50%;
    text-align: center;
    margin-top: 15px;

    @include sm {
      width: 100%;
    }
  }
  .recaptcha {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-bottom: 5px;
  }
  .btn {
    margin-top: 25px;
    width: 220px;
    height: 52px;

    @include sm {
      width: 100%;
    }
  }
}
