.table_main {
    cursor: pointer;
    .table {
        .thead {
            overflow-y: auto;
            overflow-x: hidden;
        }

        .tbody {
            height: auto;
        }

        .tr {
            border: 1px solid rgb(214 209 203 / 50%);
            box-shadow: 0px 2px 4px 0px rgb(214 209 203 / 50%);

            align-items: center;
            height: 52px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        .icon_size {
            font-size: 18px;
        }
        .tr_row {
            box-shadow: 0px 2px 4px 0px rgb(214 209 203 / 50%);
            border: 1px solid rgb(214 209 203 / 50%);
            align-items: center;
            border-top: 0;
            &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            &:hover {
                background-color: rgba(224, 224, 224, 1);
            }
            .td {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                .badge-color {
                    margin: 0 0 0 4rem;
                }
                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .typography-text::first-letter {
                    text-transform: none;
                }
            }
        }

        .th {
            font-weight: 600;
            font-size: 14px;
            display: flex !important;
            justify-content: space-between;

            align-items: center;
        }
        .td,
        .th {
            margin: 0;
            padding: 0.5rem;
            position: relative;

            :last-child {
                border-right: 0;
            }
        }
        .resizer {
            right: 0;
            background: rgba(224, 224, 224, 1);
            width: 2px;
            height: 15px;
            position: absolute;
            z-index: 1;
            touch-action: none;
        }
        .hide {
            display: none !important;
        }
    }
    .row-height {
        .tr_row {
            height: 65px;
        }
    }
}
