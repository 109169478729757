.login-option-wrapper {
    .MuiPaper-root {
        // width: 100%;
        height: 78px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0;
        border-bottom: 1px solid #d6d1cb;
        padding: 0 30px;

        .login-option-social-detail {
            display: flex;
            align-items: center;
            gap: 20px;

            @include sm {
                width: 100%;
                justify-content: space-between;
            }

            .login-option-social-text {
                display: flex;
                align-items: center;
                gap: 20px;
            }

            .login-option-social-image {
                max-width: 36px;
                img {
                    width: 36px;
                    height: 45px;
                    object-fit: contain;
                }
            }

            .social-picture {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                object-fit: contain;
            }

            .login-option-social-user-detail {
                h3 {
                    font-size: 14px;
                }
                p {
                    color: map-get($theme-colors, dark-warm-grey);
                    font-size: 14px;

                    @include sm {
                        width: 190px;
                        word-break: break-all;
                    }
                }
                .lwp_heading {
                    border: 1px solid red;
                }
                .typography-text::first-letter {
                    text-transform: none;
                }
            }
        }
    }

    @include sm {
        .MuiPaper-root {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            height: 150px;
            .login-option-button {
                margin-left: 57px;
            }
        }
    }
}
