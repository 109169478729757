.video-card-container {
    // padding-top: 4em;
    // border-top: 8px solid map-get($theme-colors, principal-maths-base);
    box-shadow: $standard-shadow;

    .star-class {
        display: flex;
        justify-content: flex-end;
    }

    .video-player {
        background: map-get($theme-colors, base-white);
        // opacity: 0.6;

        &:focus {
            outline: none;
        }

        .play-icon {
            position: absolute;
            @include sm {
                height: 72px;
                object-fit: contain;
            }
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .thumbnail_img_container {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .video_thumb {
            object-fit: contain;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    .wrapper {
        position: relative;
        padding-top: 56.25%; //16:9, Player ratio: 100 / (1280 / 720)

        .player {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
