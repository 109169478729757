.editor-container {
    border-radius: 2px;
    color: #000;
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .editor-heading-h1 {
        color: rgb(5, 5, 5);
        margin: 0;
        margin-bottom: 12px;
        padding: 0;
    }

    .editor-heading-h2 {
        color: rgb(5, 5, 5);
        margin: 0;
        margin-top: 10px;
        padding: 0;
        font-family: unset !important;
        font-weight: bold !important;
    }
}

.editor-inner {
    background: #fff;
    position: relative;
}

.editor-input {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    min-height: 150px;
    resize: none;
    font-size: 15px;
    position: relative;
    padding: 15px 10px;
    caret-color: #444;
    margin-top: 5px;
}

.editor-text-bold {
    font-weight: bold;
}

.editor-text-italic {
    font-style: italic;
}

.editor-text-underline {
    text-decoration: underline;
}

.editor-text-strikethrough {
    text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
}

.editor-text-code {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
}

.editor-link {
    color: rgb(33, 111, 219);
    text-decoration: none;
}

.editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
}

.editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
}
.editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
    color: slategray;
}

.editor-tokenPunctuation {
    color: #999;
}

.editor-tokenProperty {
    color: #905;
}

.editor-tokenSelector {
    color: #690;
}

.editor-tokenOperator {
    color: #9a6e3a;
}

.editor-tokenAttr {
    color: #07a;
}

.editor-tokenVariable {
    color: #e90;
}

.editor-tokenFunction {
    color: #dd4a68;
}

.editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
}

.editor-paragraph:last-child {
    margin-bottom: 0;
}

.editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}

.editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.editor-listitem {
    margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
    list-style-type: none;
}

pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

pre::-webkit-scrollbar-thumb {
    background: #999;
}

.toolbar {
    display: flex;
    margin-bottom: 1px;
    background: #fff;
    padding: 4px;
    vertical-align: middle;
    border: 1px solid #f1f1f1;
}

.toolbar .toolbar-item {
    border: 1px solid #f1f1f1;
    display: flex;
    background: none;
    padding: 5px;
    cursor: pointer;
    vertical-align: middle;

    &:disabled i.format {
        opacity: 0.2;
    }

    &.active {
        background-color: rgba(223, 232, 250, 0.3);
    }

    &:hover:not([disabled]) {
        background-color: #eee;
    }
}

.toolbar button.toolbar-item:disabled {
    cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
    margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
}

.toolbar .toolbar-item .text {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
}

.toolbar .toolbar-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
}

// Link editor
.link-editor {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    max-width: 400px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 0 0 8px 8px;
    transition: opacity 0.5s;
    will-change: transform;
}

.link-editor .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
}

.link-editor .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #eee;
}

.link-editor .button i,
.actions i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
}

.link-editor .button.active,
.toolbar .button.active {
    background-color: rgb(223, 232, 250);
}

.link-editor .link-input {
    display: block;
    width: calc(100% - 75px);
    box-sizing: border-box;
    margin: 12px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #eee;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
}

.link-editor .link-view {
    display: block;
    width: calc(100% - 24px);
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
}

.link-editor .link-view a {
    display: block;
    word-break: break-word;
    width: calc(100% - 33px);
}

.link-editor div.link-edit {
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.link-editor div.link-trash {
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.link-editor div.link-cancel {
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    margin-right: 28px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.link-editor div.link-confirm {
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    margin-right: 2px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.link-editor .link-input a {
    color: rgb(33, 111, 219);
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
}
