.custom_color_picker {
    width: 97% !important;
    box-shadow: 0px 4px 8px 0px rgba(214, 209, 203, 0.5) !important;

    > div:nth-child(1) {
        padding-bottom: 238px !important;
        border-radius: 8px !important;

        > div {
            &:nth-child(2) {
                display: none;
            }
        }
    }

    .flexbox-fix {
        > div:nth-child(1) > div:nth-child(2) {
            display: none;
        }

        > div:nth-child(2) {
            display: none;
        }

        &:last-child {
            display: none !important;
        }

        &:nth-child(3) {
            input {
                padding: 10px 10% !important;
                border-radius: 8px !important;
            }
        }
    }
}
