.child_parent_detials {
    h2 {
        font-family: Chaloops-Medium;
        font-weight: unset;
        margin-bottom: 30px;
    }

    .child-parent-detail-heading {
        font-size: 24px;
    }

    .re-invite-user {
        h3 {
            font-size: 16px;
            margin-bottom: 24px;
        }
        margin-bottom: 25px;
    }

    .notification-settings-container {
        margin-bottom: 20px;
    }

    .reset_password {
        h3 {
            font-size: 16px;
            margin-bottom: 24px;
        }
        margin-bottom: 25px;
    }
}
