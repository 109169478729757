.user-password-wrapper {
    .change-password-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .account-settings-heading {
            font-size: 32px;
        }
        .MuiGrid-root {
            width: 520px;
            .MuiFormControl-root {
                margin-bottom: 32px;
            }
        }
        .lwp-button-wrapper {
            button {
                padding: 8px 35px;
            }
        }
        a {
            margin-top: 24px;
        }
        @include sm {
            .account-settings-heading {
                text-align: center;
                font-size: 25px;
                padding-bottom: 0;
            }
            .MuiGrid-root {
                width: 100%;
                .MuiFormControl-root {
                    margin-top: 38px;
                    margin-bottom: 32px;
                }
            }
            .confirm-new-password {
                width: 100%;
                .MuiFormControl-root {
                    margin-top: 0px;
                }
            }
            .lwp-button-wrapper {
                width: 100%;
                button {
                    width: 100%;
                }
            }
        }
    }
}
