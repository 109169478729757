.grid-card > .activity-add-child-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 328px;
  width: 100%;
  box-shadow: $standard-shadow !important;
  border-radius: $base-radius;
  cursor: pointer;
  height: fit-content;
  // height: 330px;

  .activity-add-child-bg {
    background-position: center;
    background-repeat: repeat;
    background-size: inherit;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: 1.5em;

    .activity-add-image {
      height: 180px;
      object-fit: contain;

      @include sm {
        display: none;
      }
    }

    .activity-add-heading {
      font-size: 24px;
      padding: 0.5em 0;
    }
  }
}
