.readinglog-for-parent-page {
    .readinglog-input {
        width: 80%;
    }
    .reading-type-card-container {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-top: 24px;
        margin-bottom: 32px;
        .reading-type-card-background {
            border: 1px solid map-get($theme-colors, base-warm-grey);
            display: flex;
            justify-content: center;
            gap: 8px;
            border-radius: 5.8px;
            cursor: pointer;
            position: relative;
            .reading-type-card-image-text {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .reading-type-card {
                    background-color: map-get($theme-colors, auxiliary-yellow-light);
                    border-radius: 30px;
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 5px;
                }
            }
            .reading-type-radio-button {
                position: absolute;
                right: 8px;
                top: 2px;
                .MuiButtonBase-root {
                    padding: 0;
                    svg {
                        font-size: 16px;
                    }
                }
            }
        }
        .reading-type-card-background:first-child {
            padding: 8px 26px 4.094px 26px;
        }
        .reading-type-card-background:nth-child(2) {
            padding: 8.094px 34.764px 4.503px 34.236px;
        }
        .reading-type-card-background:last-child {
            padding: 6px 12px 5px 13px;
        }
        .active {
            background: #fff8f0;
            border: 1px solid map-get($theme-colors, principal-main-base);
        }
    }

    .suggested-books-container {
        max-width: 80%;

        @include sm {
            max-width: 100%;
        }
    }

    .mandatory-field::after {
        content: '*';
        color: map-get($theme-colors, auxiliary-red-base);
    }
    .readinglog-comment-box {
        margin-top: 24px;
        background-color: map-get($theme-colors, principal-reading-light);
        padding: 0 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border-radius: 4px;
        .MuiList-root {
            .MuiListItemIcon-root {
                min-width: 30px;
                .material-icons {
                    color: map-get($theme-colors, base-blue-black);
                }
            }
        }
        .input-field {
            label.Mui-focused {
                background-color: #ffffff;
            }
            .MuiInputBase-root {
                padding: 0;
            }
        }
    }
    .upload-img-right-content {
        background-color: map-get($theme-colors, principal-reading-light);
        padding: 0 14px 12px 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border-radius: 4px;
        .MuiListItemIcon-root {
            min-width: 30px;
            .material-icons {
                color: map-get($theme-colors, base-blue-black);
            }
        }
        .recoder-img-conatiner {
            background: rgba(255, 255, 255, 0.4);
            width: -webkit-fill-available;
            .audio-player-container {
                width: 100%;
                .audio-player {
                    width: 100%;
                    audio {
                        width: 100%;
                    }
                }
            }
        }
        .image_upload_neated_container {
            width: -webkit-fill-available;
            .dropzone-container {
                background: rgba(255, 255, 255, 0.4);
                .dropzone-para {
                    margin-bottom: 0;
                }
            }
        }
    }
    .image-uploaded-container {
        background-color: map-get($theme-colors, principal-reading-light);
        padding: 0 14px 12px 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border-radius: 4px;
        .MuiListItemIcon-root {
            min-width: 30px;
            .material-icons {
                color: map-get($theme-colors, base-blue-black);
            }
        }
        .image_upload_neated_container {
            width: -webkit-fill-available;
            .dropzone-container {
                background: map-get($theme-colors, principal-reading-light);
                border: none;
                .MuiBox-root {
                    width: 100%;
                    .dropzone-para {
                        margin-bottom: 0;
                        .lwp-button-wrapper {
                            button {
                                width: 157px;
                                background: none;
                                .material-icons {
                                    color: map-get($theme-colors, base-blue-black);
                                }
                            }
                            :hover {
                                background-color: rgba(0, 0, 0, 0.04);
                            }
                        }
                    }
                    img {
                        border-radius: 8px;
                        width: 100%;
                        max-height: 600px;
                        object-fit: contain;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    .error-message {
        display: flex;
        align-items: center;
        gap: 8px;
        p {
            color: map-get($theme-colors, auxiliary-red-base);
            font-size: 12px;
        }
    }
    .readinglog-input-card-container {
        display: flex;
        align-items: center;
        gap: 19px;
        margin-top: 24px;
        .readinglog-input-card {
            padding: 8px 22px;
            border: 3px solid map-get($theme-colors, base-warm-grey);
            border-style: dashed;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .readinglog-input-card-image-text {
                display: flex;
                align-items: center;
                gap: 16px;
                flex-direction: column;
                h1 {
                    font-size: 16px;
                    color: map-get($theme-colors, principal-reading-dark);
                }
                h2 {
                    font-size: 14px;
                    color: map-get($theme-colors, principal-reading-dark);
                }
            }
        }

        .readinglog-input-disable {
            cursor: not-allowed;
        }

        .comment-card {
            padding: 8px 14px;
        }

        .extra-width {
            max-width: 130px;
            @include sm {
                max-width: 100%;
            }
        }

        .width {
            max-width: 100px;
            @include sm {
                max-width: 100%;
            }
        }
    }

    .on-teacher {
        .readinglog-input-card {
            h2 {
                white-space: pre;
            }
        }
    }

    @include sm {
        .readinglog-input {
            width: 100%;
        }

        .reading-type-card-container {
            gap: 15px;
            .reading-type-card-background {
                .reading-type-card-image-text {
                    p {
                        text-align: center;
                        font-size: 14px;
                    }
                }
                .reading-type-radio-button {
                    right: 6px;
                    top: 1px;
                }
            }
            .reading-type-card-background:first-child {
                padding: 7.328px 12.893px 6.261px 14.658px;
            }
            .reading-type-card-background:nth-child(2) {
                padding: 10.004px 15.188px 6.261px 17.934px;
            }
            .reading-type-card-background:last-child {
                padding: 6.874px 9.967px 6.261px 9.033px;
            }
        }

        .readinglog-thoughts-fields {
            margin-left: -32px;
            width: calc(100% + 64px);
            .upload-img-right-content {
                .recoder-img-conatiner {
                    width: -webkit-fill-available !important;
                }
            }
        }

        .readinglog-input-card-container {
            gap: 14px;
            .readinglog-input-card {
                width: 91px;
                height: 107px;
                padding: 0;
            }
            .comment-card {
                padding: 0;
            }
        }

        .on-teacher {
            flex-direction: column;
            .readinglog-input-card {
                width: 100%;
            }

            .readinglog-input-heading {
                margin-top: unset !important;
            }
        }
    }
}
