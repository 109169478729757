.week_by_week {
    .table-row {
        position: relative;
        overflow: hidden;
    }

    .info_snackbar_icon {
        color: map-get($theme-colors, auxiliary-blue-base) !important;
    }

    .week_by_week_snackbar {
        .info_snackbar {
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    .week-by-week-top-options {
        .common_nav_list {
            .MuiListItem-root {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .active_date {
            font-size: 14px;
        }

        .released_tag {
            font-size: 12px;
        }
    }

    .message_pupils_text {
        .common_card {
            box-shadow: none !important;
        }
    }

    .table-row-border {
        .pupil_fullname {
            margin-bottom: 8px;
        }
    }

    .table-row-border::before {
        content: '';
        position: absolute;
        right: 0;
        height: 97%;
        bottom: 0;
        width: 4px;
        left: 0;
    }

    .border-warning::before {
        border: 1px solid map-get($theme-colors, auxiliary-yellow-base);
        background-color: map-get($theme-colors, auxiliary-yellow-base);
    }

    .border-active::before {
        border: 1px solid map-get($theme-colors, auxiliary-green-base);
        background-color: map-get($theme-colors, auxiliary-green-base);
    }

    .border-inactive::before {
        border: 1px solid #99c95d;
        background-color: #99c95d;
    }

    .table-row {
        &:last-child {
            margin: 0px;
        }
    }
}

.pupil-tab-drawer {
    .parents_carers_table_container {
        margin: 0;
    }

    .parents_carers_text {
        opacity: 0;
    }
}
