.cron-jobs-container {
    .release-pupil-topic-btn {
        margin-top: 35px !important;
    }

    .generate-csv-container {
        textarea {
            min-height: 100px;
        }
    }
    .schedule-cron-job {
        padding: 20px 0px;
    }
    .date-picker-field {
        width: 100%;
    }
}
