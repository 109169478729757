.reject-invitation-container {
    padding-top: 90px;
    width: 530px;
    margin: 0 auto;
    .common_status_container {
        margin-bottom: 33px;
        h1 {
            margin-top: 24px;
            margin-bottom: 0;
            font-size: 32px;
        }
    }
    .reject-invitation-subheading {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        margin: 0 auto;
        p {
            text-align: center;
        }
        .link-text {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    @include sm {
        width: 100%;
        .reject-invitation-subheading {
            width: 100%;
        }
    }
}