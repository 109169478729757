.msLoginBtn {
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    margin-top: 3px;
    background: none;

    svg {
        border: 1px solid #d2e3fc;
        border-radius: 4px;
    }
}