.admin-stickers {
    .sticker-character-item-card {
        .image-container {
            min-height: 154px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            p {
                font-family: Verdana;
                font-size: 12px;
                font-weight: 400;
                line-height: 140.625%;
            }
        }
    }
}
