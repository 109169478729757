.reset_password_modal {
    .MuiPaper-root {
        .paragraph {
            @include sm {
                font-size: 0.8rem !important;
            }
        }

        @include sm {
            margin: 0 24px !important;
        }
    }

    .dialog-content-section {
        min-height: 284px;
        display: flex;
        flex-direction: column;
    }

    .title {
        font-style: italic;
    }

    .MuiDialogContent-root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .reset_pwd_link_contain {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .typography-text {
            text-decoration: underline;
            cursor: pointer;
        }

        .icon {
            cursor: pointer;
        }
    }

    .btn_contain {
        text-align: end;
    }
}
