.common_sortable_table_container {
    .MuiListItemIcon-root {
        min-width: 30px;
    }

    .heading-three {
        font-size: 14px;
    }

    .redColor {
        background-color: map-get($theme-colors, auxiliary-red-base);
    }

    .redColorLight {
        background-color: map-get($theme-colors, auxiliary-red-light);
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        h2 {
            flex: 1;
            font-family: Chaloops-Medium;
            font-weight: unset;
        }
    }

    .download_button {
        text-decoration: none;
        font-weight: bold;
        color: #000000;
        font-size: 14px;
        margin-bottom: 0.25rem;
        margin: 0;
        padding: 0;
        text-align: left;

        &.hover {
            background-color: rgba($color: #000000, $alpha: 0.04);
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .MuiTableSortLabel-icon {
        color: map-get($theme-colors, base-blue-black) !important;
    }
    .MuiTableSortLabel-root {
        &:hover {
            color: map-get($theme-colors, base-black);
        }
    }
}
