.edit-class-detail-popover {
  max-width: 285px;
  // min-height: 180px;
  border-radius: $base-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .nested-container {
    margin-bottom: 7px;
    width: 100%;
  }

  .pen-icon {
    margin-right: 19px;
    color: map-get($theme-colors, base-blue-black);
  }

  .text-container {
    background-color: map-get($theme-colors, auxiliary-yellow-light);
    padding: 19px;
    display: flex;

    .question-icon {
      color: map-get($theme-colors, principal-main-base);
      margin-right: 10px;
      font-size: 26px;
    }
  }
}
