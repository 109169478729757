.phone_input_contain {
    .PhoneInput {
        padding: 1em;
        border: 1px solid rgba(0, 0, 0, 0.23) !important;
        border-radius: 4px;
    }

    .PhoneInputInput {
        border: none;
        outline: none;
        font-size: 1em;
        color: #000000;
    }
}
