.layout-container {
    // max-width: 1032px;
    max-width: 1080px;
    margin: 0 auto;
    height: inherit;
    width: 100%;
    // padding: 0;
    padding: 0 24px !important;
}

.lwp-feedback {
    .desktop-btn {
        display: flex;
        justify-content: flex-end;
    }

    .m-30 {
        margin: 30px 0;
    }

    .mb-20 {
        margin-bottom: 35px;
    }

    .feedback-form-container {
        padding: 10px;
        width: 90%;
        margin: auto;
    }
}

.feedback_widget-section {
    display: block;
    // position: relative;
    height: 650px;
    width: 90%;
    margin: 20px auto;

    .feedback-form {
        width: 100%;
        height: 100%;
    }
}

// @media (min-width: 375px) and (max-width: 768px) {
//   .layout-container {
//     padding: 0 24px !important;
//   }
// }

@include sm {
    .LWPButton {
        width: 327px;
    }
}

@include md {
    .layout-container {
        padding: 0 64px !important;
    }
}

@include mw(1161) {
    .MuiContainer-root {
        padding: 0;
    }
}

.MuiContainer-root {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-left: auto !important;
    margin-right: auto !important;
}
.resources-layout-container {
    max-width: none !important;
    margin: 0 !important;
    padding: 0;
}
.admin-layout-container {
    max-width: 100% !important;
    margin: 6em 0;
}

.teacher-layout-root {
    position: relative;

    .teacher-layout-position {
        position: absolute;
        right: 0;
        top: 20px;
        margin-right: 20px;
    }

    .teacher-layout-container {
        max-width: 1080px;
        width: 100%;
        height: inherit;
        margin: 6em auto;

        @include maw(767) {
            width: 100% !important;
            margin: 3em 0;
        }

        .text-fields {
            display: flex;
            flex-direction: column;
            max-width: 405px;
            padding: 20px 0;

            .lwp-button {
                margin-top: 20px;
            }
        }

        .account-settings-heading {
            font-size: 1.5rem;
            padding-bottom: 1.25rem;
        }

        .import-pupil-from-wonde-class {
            max-width: 400px;
            min-width: 100px;
            margin-top: 32px;
            .heading-three {
                margin-bottom: 20px;
            }
        }

        .add-class-page {
            .programme-options-container {
                // margin-top: 13px;
                .programme-info-alert-container {
                    width: 100%;
                    height: 63.13px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    background-color: map-get($theme-colors, auxiliary-blue-light);
                    margin: 16px 0;
                    .programme-info-alert-mathscot-container {
                        width: 38px;
                        height: 38px;
                        background: rgba(57, 176, 226, 0.6);
                        border-radius: 25px;
                        margin-left: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 45px;
                            height: 43px;
                            object-fit: none;
                            margin-left: 8px;
                        }
                    }
                    .programme-info-alert-text {
                        margin: 0 10px 0 20px;
                        p {
                            font-size: 14px;
                        }
                    }
                }
                .programme-options-isFullProgramme-checkbox {
                    display: flex;
                    align-items: center;
                    .common-tool-tip-icon {
                        margin-top: -8px;
                    }
                }
                .date-picker-error {
                    .date-picker-field {
                        background-color: map-get($theme-colors, auxiliary-red-light);
                        .MuiFormLabel-root {
                            color: map-get($theme-colors, base-black);
                            background-color: map-get($theme-colors, auxiliary-red-light);
                        }
                        .MuiOutlinedInput-notchedOutline {
                            border: 1px solid map-get($theme-colors, auxiliary-red-base);
                        }
                    }
                }
                .date-picker-field {
                    width: 100%;
                    .MuiInputAdornment-root {
                        button {
                            color: map-get($theme-colors, base-blue-black);
                        }
                    }
                }
                .date-error-message {
                    display: flex;
                    align-items: flex-start;
                    gap: 8px;
                    margin-bottom: 8px;
                    .MuiSvgIcon-root {
                        font-size: 14px;
                        margin-top: 8px;
                        color: map-get($theme-colors, auxiliary-red-base);
                    }
                    p {
                        margin-top: 8px;
                        font-size: 12px;
                        color: map-get($theme-colors, auxiliary-red-base);
                    }
                }
            }
        }

        .mathscots-header {
            h1 {
                font-size: 24px;
            }
        }
    }
}

.other_layout_for_specific_pages {
    max-width: 828px;
    margin: 0 auto;

    .activity-feed-header {
        margin-bottom: 1rem;
    }

    .activity-feed-title {
        .typography-text {
            margin-top: 0 !important;
            font-size: 16px !important;
            u {
                cursor: pointer;
                color: map-get($theme-colors, base-blue-black);
            }
        }
    }

    .feed-date-container {
        width: 100%;
        display: flex;
        .MuiGrid-root {
            margin-left: 24px;
            .MuiTextField-root {
                width: 100%;
            }
        }
        .MuiInputLabel-root {
            color: black;
        }
    }

    .mb-20 {
        justify-content: flex-end;
        margin-bottom: 20px;
    }
    .filter-button {
        color: black;
        text-transform: initial;
        font-weight: bold;
    }

    .filter-icon {
        margin-top: 5px;
        margin-left: 5px;
    }

    .feeds-filter-container {
        margin-bottom: 20px;

        .MuiCardActions-root {
            justify-content: flex-end;
        }
        .MuiCardActions-spacing > :not(:first-child) {
            margin-left: 30px;
        }
    }
}

.caught_up_on_container {
    position: relative;
    box-shadow: 0px 4px 8px 0px rgba(214, 209, 203, 0.5) !important;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        top: 0px;
        background-color: #f28d00;
    }

    .caught_up_on_feedback {
        margin: 24px auto;
        display: flex;
    }
    .caught_up_on_heading {
        margin-bottom: 16px;
    }
    .caught_up_on_para {
        margin-bottom: 24px;
    }
}

.feedback_tooltip_text {
    p {
        font-size: 20px;
        font-weight: 400;
    }
}

.teacher_feedback_popover {
    .MuiPaper-rounded {
        &::before {
            right: 45%;
            left: auto;

            @include sm {
                right: 16%;
                left: auto;
            }
        }
    }
}
