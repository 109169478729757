.common-survey-info-container {
    background-color: map-get($theme-colors, auxiliary-yellow-light);
    height: 62px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 15px 10px 10px;
    border-radius: 4px;
    margin: 0 auto;
    .common-survey-info-image-container {
        background-color: #ffe799;
        width: 42px;
        height: 42px;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .common-survey-info-text {
        display: flex;
        align-items: center;
        h1 {
            font-size: 20px;
            color: map-get($theme-colors, principal-main-base);
        }
        .common-survey-info-link-text {
            padding-left: 3px;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    @include sm {
        .common-survey-info-text {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
