.school-analytics-container {
    > h1 {
        margin-bottom: 32px;
    }
    .school-analytics-grid {
        .school-analytics-card {
            width: 100%;
            border-radius: 4px;
            padding: 24px 16px;
            display: flex;
            gap: 32px;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            background-color: #ffffff;

            @include sm {
                padding-right: 0px;
            }

            h2 {
                font-size: 20px;
                margin-bottom: 8px;
            }
        }
        .READING {
            border-left: 7px solid map-get($theme-colors, principal-reading-base);
        }
        .TOPIC_ACTIVITY {
            border-left: 7px solid map-get($theme-colors, principal-maths-base);
        }
    }
}

.school-analysis-metric {
    .school-analysis-metric__title {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        width: fit-content;
        h3 {
            margin-bottom: 0px;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.2;
        }
        margin-bottom: 60px;
    }
    .school-analysis-metric-content {
        border-radius: 8px;
        box-shadow: 0px 4px 8px 0px rgba(214, 209, 203, 0.5);
        padding: 27px;

        .school-analysis-heading {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        .school-analysis-metric-graph-container {
            margin-top: 20px;
            border-radius: 6px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            .MuiPaper-root {
                box-shadow: none;
                > div {
                    border-radius: 4px;
                    border: 1px solid #c9c9c9;
                    padding: 10px 0px;
                    .highcharts-container {
                        padding-top: 50px;
                    }
                }
            }
        }

        .READING {
            border-top: 6px solid map-get($theme-colors, principal-reading-base);
            background-color: #eff2fe;
        }

        .TOPIC_ACTIVITY {
            border-top: 6px solid map-get($theme-colors, principal-maths-base);
            background-color: map-get($theme-colors, auxiliary-green-light);
        }
    }
}
