.brand_logo_container {
    max-width: 150px;
    max-height: 100px;
    padding: 5px;

    img {
        width: 100%;
        height: 100%;
    }
}

.brand_logo_mein_container{
    display: flex;
    align-items: center;
    justify-content: center;
}
