.link-pupils-to-mis {
    .MuiDialog-paperWidthSm {
        max-width: 900px !important;
        width: 100%;
    }
    .dialog-content-section{
        .misInfoIcon {
            width: 35px;
            font-size: 35px;
        }
    }
}