.create-new-sticker {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .heading-two {
        font-family: Chaloops-Medium;
        font-weight: normal;
        line-height: 34px;
    }

    .select-color {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 20px 0px;
    }

    .upload-sticker {
        > div {
            margin-top: 40px;
        }

        .selected-image {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .image_editor_container {
                width: 100%;
            }
        }
    }

    .create-sticker {
        > div {
            margin-top: 40px;
            @include sm {
                flex-direction: column-reverse;
            }
        }

        .image-preview {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 15px;
            img {
                width: 100%;
                object-fit: contain;
            }
            p {
                text-align: center;
                font-family: Verdana;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
            }
        }
        .color-combination-warning {
            background-color: #fce9e5;
            width: 100%;
            max-width: 337px;
            padding: 16px;
            p {
                margin-top: 4px;
                font-family: Verdana;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                text-align: start;
            }
        }
    }
}
.edit-sticker {
    gap: 0px;
    .edit-sticker-header {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    .edit-sticker-warning {
        background-color: #fff6d9;
        padding: 16px;
        display: flex;
        gap: 16px;
        align-items: center;
        p {
            font-family: Verdana;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;

            a {
                color: map-get($theme-colors, base-black);
                font-weight: 600;
            }
        }
    }
}

.character-item-select-dialogue {
    .MuiDialog-container {
        > div {
            margin: 30px !important;
        }
    }
}
.select-image-container {
    .item-image {
        width: 100%;
        padding: 8px;
        height: 150px;
        cursor: pointer;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
    .item-image-active {
        border-radius: 4px;
        border: 1px solid map-get($theme-colors, principal-main-base);
    }
}

.select-color-popover {
    max-width: 436px;
}
