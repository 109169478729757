.resources_page_container {
    // margin-bottom: 16px;
    width: 100%;
    height: 100%;

    .resources {
        width: 100%;
        height: 99.7vh;
        border: none;
    }
}
