.schedule {
    .class-register-header-container {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 24px;
        .class-register-header-image-reading {
            width: 34px;
            height: 34px;
            background-color: map-get($theme-colors, principal-reading-light);
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 34px;
                height: 18px;
                margin-left: 3px;
                object-fit: none;
            }
        }
        .class-register-header-image-fullProgramme {
            width: 34px;
            height: 34px;
            background-color: map-get($theme-colors, principal-maths-light);
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 28px;
                height: 31px;
                object-fit: none;
            }
        }
        .heading {
            font-size: 1.5rem;
            margin-right: 22px;
        }
    }
    .schedule-topic-heading {
        font-size: 1.5rem;
        padding: 10px 0 15px 0;
    }
    .add-topic-filters-dropdowns {
        display: flex;
        justify-content: space-between;
        .MuiGrid-root {
            width: 325px;
        }
    }
    .add-topic-search {
        display: flex;
        align-items: center;
        .MuiGrid-root {
            max-width: 325px;
            margin-bottom: 0;
            .MuiOutlinedInput-input {
                padding: 1em 0;
            }
        }
        .search-button-container {
            margin-left: 36px;
            display: flex;
            gap: 30px;
        }
    }

    .zero-reading-target-bg {
        background: #f5f5f5;
    }
    .not-zero-reading-target-bg {
        background: #e5f3fa;
    }
    .reading-target-container {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        .reading-target {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            width: 30px;
            height: 30px;
            p {
                color: white;
                margin-bottom: 0;
                font-family: Chaloops-Medium;
                font-size: 27px;
            }
        }
        .preview-icon {
            color: map-get($theme-colors, base-blue-black);
        }
    }
    .reading-PopOver {
        h3 {
            font-size: 14px;
        }
    }
    .zero-bg {
        color: map-get($theme-colors, dark-warm-grey);
    }
    .not-zero-bg {
        background-color: map-get($theme-colors, principal-reading-dark);
    }
    .schedule-show-more-btn {
        margin-top: 64px;
        display: flex;
        justify-content: center;
        button {
            background: map-get($theme-colors, base-warm-grey);
        }
    }
    .classTopic-table {
        margin-top: 32px;
    }

    .no_match_item_status {
        padding: 27px 0 40px 0;
        box-shadow: 0px 4px 8px rgba(214, 209, 203, 0.5);
        p {
            max-width: 400px;
        }
    }
    .schedule-reading-info {
        img {
            margin-top: 3px;
        }
        p {
            font-size: 16px;
            .schedule-class-reading-target-info-container {
                display: flex;
                align-items: center;
                gap: 7px;
                .schedule-reading-target {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    background-color: map-get($theme-colors, principal-reading-dark);
                    width: 29px;
                    height: 29px;
                    p {
                        color: #fff;
                        font-family: Chaloops-Medium;
                        font-size: 25px;
                        margin-left: 0;
                    }
                }
            }
            .schedule-class-details-page {
                cursor: pointer;
                color: map-get($theme-colors, base-blue-black);
            }
        }
    }
}

.domain_table {
    .tr_row {
        height: 55px;
        background: #fff;
    }
}

.schedule-table-actions-table-cell {
    padding-left: 0;
    width: 0px;
}

.schedule-table-actions-container {
    display: flex;
    align-items: center;
    gap: 21px;
    cursor: pointer;
    padding: 7px 15px;
}

.schedule-popover {
    .MuiListItem-root {
        .MuiListItemIcon-root {
            min-width: 30px;
            .material-icons {
                display: flex;
            }
        }
    }
}

.schedule-topic-cards {
    margin-top: 45px;
    .loading_container {
        margin: 0 auto;
    }
}
