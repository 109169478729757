.add_parent_modal {
    .dialog-content-section {
        .MuiDialogContent-root {
            .add-activity-step-dropdown {
                .add-step-type-btn {
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .add-term-modal-grid {
                .MuiGrid-root {
                    margin-bottom: 1em;

                    .MuiFormControl-root {
                        width: 100%;
                        .MuiInputLabel-root {
                            color: #000;
                        }
                    }
                }
            }

            .admin-query-textarea {
                textarea {
                    min-height: 100px;
                }
            }
        }

        @include sm {
            width: 327px;
        }
    }

    .btn_group {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;

        .cancel_btn {
            margin-right: 10px;
            padding: 8px 22px;
        }
    }
}
