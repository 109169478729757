@mixin banner {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    font-weight: 700;
    white-space: pre;
    z-index: 5;

    .link {
        font-weight: 700 !important;
    }
}
