.add-partnership-modal {
    .add-resource-file-upload {
        align-items: flex-start !important;
        input {
            margin-left: 0px !important;
        }
    }

    .divider {
        margin: 15px 0px;
    }
}
