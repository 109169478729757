.main_sortabel_table {
    .MuiDataGrid-columnHeaderTitle {
        font-weight: bold;
    }
    .MuiDataGrid-columnHeaders {
        background-color: #fcfcfd !important;
    }
    .MuiDataGrid-row.Mui-selected {
        background-color: transparent !important;
    }

    .MuiDataGrid-cell:focus-within,
    .MuiDataGrid-columnHeader:focus,
    .MuiDataGrid-cell:focus {
        outline: none !important;
    }
}
