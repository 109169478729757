.teacher_resources {
    .MuiListItemIcon-root {
        max-width: 30px;
    }

    .link {
        color: #0000EE;
        font-size: 14px;
    }

    .img_container {
        max-width: 64px;
        img {
            width: 100%;
        }
    }
}
